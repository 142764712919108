<template>
    <div class="contactUs_page">
        <Banner :adCategory="7"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="title">联系我们</div>
        <div class="content">
            <div class="contact_details flex flex-wrap">
                <div class="list flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/1.png" alt="">
                    <div class="label">客服热线</div>
                    <div class="cont">{{ formData.serverPhoneNumber }}</div>
                </div>
                <div class="list flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/2.png" alt="">
                    <div class="label">电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话</div>
                    <div class="cont">{{ formData.telephone }}</div>
                </div>
                <div class="list flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/3.png" alt="">
                    <div class="label">传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真</div>
                    <div class="cont">{{ formData.fax }}</div>
                </div>
                <div class="list flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/4.png" alt="">
                    <div class="label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱</div>
                    <div class="cont">{{ formData.email }}</div>
                </div>
                <div class="list postal_code flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/5.png" alt="">
                    <div class="label">邮政编码</div>
                    <div class="cont">{{ formData.zipCode }}</div>
                </div>
                <div class="list postal_code flex-center">
                    <img class="icon_img" src="@/assets/img/contactUs/6.png" alt="">
                    <div class="label">总部地址</div>
                    <div class="cont">{{ formData.companyAddress }}</div>
                </div>
            </div>
            <div class="map">
                <iframe class="icon_map" src="https://www.golkia.net/words/companyMap.html" frameborder="0"
                    scrolling="no"></iframe>
            </div>
            <div class="content officeInfo">
                <div class="office_title">驻外办事处</div>
                <div class="fonmData">
                    <el-table :data="formData.foreignOfficesVOS" style="width: 100%">
                        <el-table-column prop="areaAddress" align="center" label="区域">
                        </el-table-column>
                        <el-table-column prop="contactName" align="center" label="联系人">
                        </el-table-column>
                        <!-- <el-table-column
                        prop="telephone"
                        align="center"
                        label="联系方式">
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
export default {
    components: {
        Banner,
        Breadcrumb
    },
    data() {
        return {
            breadcrumbList: [
                {
                    name: "联系我们",
                    url: ''
                },
            ],
            formData: {}
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.api.findPlatformInfo().then(res => {
                this.formData = res.data
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.contactUs_page {
    .breadcrumb_box {
        background: #f4f5f5;
    }

    .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        text-align: center;
        padding-top: 79px;
    }

    .contact_details {
        margin-top: 82px;

        .postal_code {
            width: 1200px !important;
        }

        .list {
            width: 500px;
            margin-bottom: 60px;

            .icon_img {
                width: 38px;
                height: 38px;
            }

            .label {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-left: 25px;
                margin-right: 27px;
            }

            .cont {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #888888;
            }
        }
    }

    .map {
        .icon_map {
            width: 1200px;
            height: 582px;
        }
    }

    .officeInfo {
        padding-bottom: 70px;

        .office_title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #E62129;
            margin-top: 60px;
            margin-bottom: 37px;
            padding-left: 20px;
        }

    }
}
</style>
<style>
.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
    word-break: break-word;
    background-color: #f5f5f5;
    color: #515a6e;
    height: 60px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    border-top: 2px solid #E62129;
}

.el-table tr {
    height: 60px;
}

.el-table tr td {
    font-size: 18px;
    color: #666666;
}
</style>